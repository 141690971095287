/* Global Style */

@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

body {
  background: rgba(10, 10, 10, 1);
  color: #fff;
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-optical-sizing: auto;
  font-weight: normal;
  font-style: normal;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  
}



h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Space Grotesk", sans-serif;

}

ul.list_style {
  list-style: none;
  list-style-position: outside;
}

ul.list_style li {
  line-height: 2rem;
}

.mobile_offcanvas_body {
  background-color: #000;
}
.sticky {
  /* position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%; */
  z-index: 1030;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(4px);
  border-bottom: 1px solid rgb(255 255 255 / 10%);
}

.custom_nav {
  transition: top 0.3s;
  /* Smooth transition for stickiness */
}

.anchor_link_orange {
  color: #db8f00 !important;
  text-decoration: none;
}


.anchor_link {
  text-decoration: underline;
  color: rgba(239, 247, 255, .62);

}

.anchor_link:hover {
  text-decoration: none;
  color: rgb(255, 255, 255);

}

.fs-14 {
  font-size: 14px !important;
}

.fs-12 {
  font-size: 12px !important;
}

.spacer-lg {
  padding-top: 6rem;
  padding-bottom: 6rem;
}


.spacer {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.spacer-sm {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.container {
  max-width: 1200px !important
}

.container_sm {
  width: 800px;
  /* display: flex;
  justify-content: center; */
}

.main_title {
  font-size: 48px;
  line-height: 72px;
  font-weight: 700;
}

.logoStyle {
  width: 41px;
  height: 42px;
}

.custom_nav .nav-link {
  color: rgba(239, 247, 255, .62);
  font-size: 16px;
  line-height: 32px;
  font-weight: 500;

}

.custom_nav .nav-link:hover {
  color: #fff;
}

a.spcl_menu {
  color: #db8f00 !important;
}

a.spcl_menu:hover {
  color: #fcb228 !important;
}

.welcome_area {
  padding-top: 5rem;
  padding-bottom: 8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-size: cover;
}

.background-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url(/public/images/gray-logo-bg.png) no-repeat center;
  background-size: 14%;
  opacity: 0.1;
  /* Set opacity to 0.1 */
  z-index: -1;
  /* Ensure the overlay is behind the content */
}

.container_sm {
  position: relative;
  z-index: 1;
  /* Ensure the content is above the overlay */
}


.bg-top {
  flex: none;
  height: 1023px;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  width: 941px;
}

.bg-top-inner {
  bottom: 0;
  flex: none;
  height: 1551px;
  left: 0;
  opacity: .3;
  pointer-events: none;
  position: absolute;
  width: 1434px;
  z-index: 0;
}

/* .bg-top-img {
   filter: blur
} */

.badge_welcome {
  background-color: #db8f00;
  color: #000;
  font-weight: bold;
  border-radius: 9999px;
  box-shadow: 0 0 0 1px #0000000d;
  display: inline-block;
  flex: none;
  flex-direction: row;
  flex-wrap: nowrap;
  padding: 10px 18px;
}

.main_heading {
  font-size: 64px;
  line-height: 77px;
  font-weight: 700;
}

.sub_heading {
  font-size: 32px;
  line-height: 38px;
  font-weight: 700;
}

.sub_title {
  font-size: 36px;
  font-weight: 800;
  line-height: 43px;
}

.para_head {
  font-size: 19px;
  font-weight: 600;
  line-height: 24px;
  color: rgb(255, 255, 255);
}

.para {
  font-size: 18px;
  line-height: 27px;
  font-weight: 500;
  color: rgba(239, 247, 255, 0.62)
}

.custom_btn {
  border: 1px solid #e5e7eb;
  background-color: #fff;
  color: #000;
  font-size: 15px;
  font-weight: 600;
  border-radius: 9999px;
  overflow: visible;
  padding: 12px 21px;
  position: relative;
  text-decoration: none;
  width: min-content;
  display: inline;
}

.action_btn {
  border: 1px solid #e5e7eb;
  font-size: 15px;
  font-weight: 600;
  background-color: #fff;
  color: #000;
  border-radius: 9999px;
  overflow: visible;
  padding: 6px 12px;
  position: relative;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
}


.content-fold-img {
  bottom: -162px;
  flex: none;
  height: 246px;
  pointer-events: none;
  position: absolute;
  right: -171px;
  width: 239px;
  z-index: 0;
}


.content_fold_wrapper {
  aspect-ratio: 1 / 1;
  background-color: #ffffff0a;
  border-radius: 20px;
  position: relative;
  flex: none;
  overflow: hidden;
  position: relative;
  width: 540px;
}

.image_backdrop_orange {
  width: 100%;
  height: 100%;
  background-color: transparent;
  background-image: radial-gradient(circle at center center, rgb(219, 143, 0), rgb(0, 14, 51)), repeating-radial-gradient(circle at center center, rgb(219, 143, 0), rgb(219, 143, 0), 21px, transparent 42px, transparent 21px);
  background-blend-mode: multiply;
  border-radius: 0;
}

.image_backdrop_green {
  width: 100%;
  height: 100%;
  background-color: transparent;
  background-image: radial-gradient(circle at center center, rgb(44, 102, 14), rgb(116, 245, 42)), repeating-radial-gradient(circle at center center, rgb(44, 102, 14), rgb(44, 102, 14), 21px, transparent 42px, transparent 21px);
  background-blend-mode: multiply;
  border-radius: 0;
}

.image_backdrop_teal {
  width: 100%;
  height: 100%;
  background-color: transparent;
  background-image: radial-gradient(circle at center center, rgb(14, 102, 102), rgb(42, 245, 228)), repeating-radial-gradient(circle at center center, rgb(14, 102, 102), rgb(14, 102, 102), 21px, transparent 42px, transparent 21px);
  background-blend-mode: multiply;
  border-radius: 0;
}


.image_backdrop_blue {
  width: 100%;
  height: 100%;
  background-color: transparent;
  background-image: radial-gradient(circle at center center, rgb(2, 119, 214), rgb(9, 1, 117)), repeating-radial-gradient(circle at center center, rgb(2, 119, 214), rgb(2, 119, 214), 21px, transparent 42px, transparent 21px);
  background-blend-mode: multiply;
  border-radius: 0;
}

.image_backdrop_multicolor {
  width: 100%;
  height: 100%;
  background-color: transparent;
  background-image: radial-gradient(circle at center center, rgb(199, 2, 189), rgb(0, 105, 171)), repeating-radial-gradient(circle at center center, rgb(199, 2, 189), rgb(199, 2, 189), 21px, transparent 42px, transparent 21px);
  background-blend-mode: multiply;
  border-radius: 0;
}

.image_backdrop_pink {
  width: 100%;
  height: 100%;
  background-color: transparent;
  background-image: radial-gradient(circle at center center, rgb(250, 179, 158), rgb(199, 101, 246)), repeating-radial-gradient(circle at center center, rgb(250, 179, 158), rgb(250, 179, 158), 21px, transparent 42px, transparent 21px);
  background-blend-mode: multiply;
  border-radius: 0;
}

.image_backdrop_wrapper {
  bottom: -581px;
  flex: none;
  left: -305px;
  position: absolute;
  top: -29px;
  width: 213%;
}

.image_backdrop_inner {
  -webkit-backdrop-filter: blur(21px);
  aspect-ratio: 1.0454545454545454 / 1;
  backdrop-filter: blur(21px);
  background-color: #0003;
  border-radius: 15px;
  flex: none;
  /* height: 406px; */
  left: 50%;
  overflow: visible;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 424px;
}

.whitepaper_content {
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000;
}

.whitepaper_content svg:hover {
  transition: all .3s ease-in-out;
  cursor: pointer;
}

.whitepaper_content svg:hover {
  transform: scale(1.2);
}

.team_img_backdrop {
  height: 350px;
}



.token_img_backdrop {
  height: 380px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000;
}

.token_img_box {
  aspect-ratio: 1 / 1;
  border-radius: 20px;
  flex: none;
  height: 80px;
  overflow: hidden;
  position: relative;
  text-decoration: none;
  width: 80px;
  transition: all .3s ease-in-out;
}

.token_img_box:hover {
  transform: scale(1.2);
}

.image_backdrop_img {
  aspect-ratio: 1.0454545454545454 / 1;
  border-radius: 15px;
  flex: none;
  height: 274px;
  left: 50%;
  overflow: visible;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 286px;
}

.custom_card {
  border: 1px solid rgba(255, 255, 255, .07);
  align-content: flex-start;
  align-items: flex-start;
  align-self: start;
  background-color: #171717;
  border-radius: 12px;
  display: flex;
  flex: none;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 20px;
  height: 100%;
  justify-content: flex-start;
  justify-self: start;
  overflow: hidden;
  padding: 33px;
  position: relative;
  width: 100%;
}

.custom_card_transparent {
  /* border:1px solid rgba(255, 255, 255, .07);   */
  align-content: flex-start;
  align-items: flex-start;
  align-self: start;
  /* background-color: #171717; */
  border-radius: 12px;
  display: flex;
  flex: none;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 20px;
  height: 100%;
  justify-content: flex-start;
  justify-self: start;
  overflow: hidden;
  padding: 33px;
  position: relative;
  width: 100%;
  cursor: pointer;
}


.cs_card {

  border: 1px solid rgba(255, 255, 255, .08);
  /* align-content: center;
  align-items: center; */
  background-color: #171717;
  border-radius: 24px;
  display: flex;
  flex: none;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 467px;
  justify-content: space-between;
  overflow: hidden;
  padding: 33px 33px 8px;
  position: relative;
  text-decoration: none;
  /* width: 461px; */
}

.cs_card_bimg {
  /* aspect-ratio: 1.1389578163771712 / 1; */
  bottom: 0;
  flex: none;
  height: 308px;
  left: 0;
  overflow: visible;
  position: absolute;
  right: 0;
  z-index: 1;
}


.cs_card_bimg2 {
  border-bottom-left-radius: 22px;
  border-bottom-right-radius: 22px;
  bottom: 1px;
  flex: none;
  height: 344px;
  left: 1px;
  overflow: visible;
  position: absolute;
  right: 1px;
  z-index: 1;
}

.cs_card_bimg4 {
  aspect-ratio: 1 / 1;
  flex: none;
  height: 244px;
  overflow: visible;
  position: relative;
}

.cs_card_bimg5 {
  aspect-ratio: 1 / 1;
  flex: none;
  height: 255px;
  overflow: visible;
  position: relative;
  width: 255px;
}

.cs_card_bimg1 img,
.cs_card_bimg2 img,
.cs_card_bimg4 img,
.cs_card_bimg5 img {
  object-fit: unset;
  width: 100%;
}

.cs2_card {

  border: 1px solid rgba(255, 255, 255, .08);
  align-content: center;
  align-items: center;
  background-color: #171717;
  border-radius: 24px;
  display: flex;
  flex: 1 0 0px;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 467px;
  justify-content: space-between;
  overflow: hidden;
  padding: 33px 33px 8px;
  position: relative;
  text-decoration: none;
  width: 1px;
  will-change: var(--framer-will-change-override, transform);
}

.sub_desc {
  font-size: 14px;
  line-height: 23px;
  font-weight: 500;
  color: #a9a9a9;

}

/* ========Accordion Style overrides ========*/
.accordion-item {
  border: 1px solid #181818 !important;
  margin-bottom: 5px;
  background-color: #181818;
  font-family: "Inter", sans-serif;
}

.accordion-button {
  background-color: #181818;
  color: white;
  font-size: 17px;
  font-family: "Inter", sans-serif;
}

button:focus:not(:focus-visible) {
  outline: 0;
  box-shadow: none !important;
}

.accordion-body {
  color: #A6ABB1;
  font-family: "Inter", sans-serif;

}

.accordion-button:after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='white'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.accordion-button:not(.collapsed):after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='white'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.accordion-button:not(.collapsed) {
  background-color: #181818;
  color: white;
  box-shadow: none !important;
}

.long_content svg {
  font-size: 21px;
}


.dmt_coupon_bg_wrapper {
  aspect-ratio: 2.290258449304175 / 1;
  flex: none;
  height: 524px;
  /* min-width: 920px; */
  overflow: hidden;
  position: relative;
  width: 100%;
}

.dmt_coupon_bg {
  align-content: center;
  align-items: center;
  aspect-ratio: 2.290258449304175 / 1;
  border-radius: 16px;
  display: flex;
  flex: none;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 30px;
  height: 503px;
  justify-content: center;
  left: 50%;
  overflow: hidden;
  padding: 0 94px;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 96%;
}

.dmt_coupon_img_wrapper {
  position: absolute;
  border-radius: inherit;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.dmt_img {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  object-position: center;
  /* object-fit: contain; */
  image-rendering: auto;
}

.dmt_content_wrapper {
  align-content: center;
  align-items: center;
  display: flex;
  flex: none;
  flex-direction: row;
  flex-wrap: nowrap;
  height: 404px;
  justify-content: space-evenly;
  left: calc(50.00000000000002% - 96% / 2);
  overflow: visible;
  padding: 0 48px;
  position: absolute;
  top: calc(50.00000000000002% - 404px / 2);
  width: 100%;
}

.dmt_content_wrapper_left {
  align-content: flex-start;
  align-items: flex-start;
  display: flex;
  flex: none;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 24px;
  height: min-content;
  justify-content: flex-start;
  /* overflow: hidden; */
  padding: 0;
  position: relative;
  width: 60%;
}

.dmt_content_left {
  flex: none;
  height: auto;
  max-width: 920px;
  position: relative;
  white-space: pre-wrap;
  width: 500px;
  word-break: break-word;
  word-wrap: break-word;
}

.dmt_content_wrapper_right {
  aspect-ratio: 1.00990099009901 / 1;
  flex: none;
  height: 404px;
  overflow: visible;
  position: relative;
  width: 408px;
}

.dmt_content_wrapper_right_card {
  -webkit-backdrop-filter: blur(100px);
  backdrop-filter: blur(100px);
  background-color: #0009;
  border-radius: 15px;
  flex: none;
  inset: 2px 4px;
  overflow: visible;
  position: absolute;
}

.dmt_content_wrapper_right_card_inner {
  -webkit-backdrop-filter: blur(0px);
  backdrop-filter: blur(0px);
  border-radius: 15px;
  flex: none;
  inset: 3px -46px 0;
  overflow: visible;
  position: absolute;
}

.bgcolor_img_wrapper {
  position: absolute;
  border-radius: inherit;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.dmt_img_style {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  object-position: center;
  object-fit: contain;
  image-rendering: auto;
}

.main_content_wrapper {
  -webkit-backdrop-filter: blur(0px);
  backdrop-filter: blur(0px);
  border-radius: 15px;
  flex: none;
  height: 153px;
  left: calc(49.800000000000026% - 145px / 2);
  overflow: visible;
  position: absolute;
  top: 1px;
  width: 145px;
}

/* .mcw_img_wrapper {
  position: absolute;
  border-radius: inherit;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
} */

/* .mcw_img {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  object-position: center;
  object-fit: contain;
  image-rendering: auto;
} */

.power_img_wrapper {
  bottom: 66px;
  flex: none;
  height: 208px;
  left: calc(50.00000000000002% - 225px / 2);
  position: absolute;
  width: 225px;
}



.experience_wrapper {
  align-content: center;
  align-items: center;
  aspect-ratio: 1.78125 / 1;
  display: flex;
  flex: none;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 0px;
  height: 640px;
  justify-content: center;
  overflow: hidden;
  padding: 0 94px;
  position: relative;
  width: 100%;
  margin-bottom: 2rem;
}

.exp_backdrop {
  flex: 1 0 0px;
  height: 530px;
  overflow: visible;
  position: relative;
  width: 1px;
}

.exp_backdrop_inner {
  aspect-ratio: 1.7982873453853472 / 1;
  flex: none;
  height: 530px;
  left: 50%;
  overflow: visible;
  position: absolute;
  top: 50%;
  width: 952px;
  backdrop-filter: blur(100px);
  background-color: rgba(0, 0, 0, 0.6);
  transform: translate(-50%, -50%);
  border-radius: 15px;
  opacity: 1;


}

.experience_img_container {
  flex: none;
  inset: 64px 101px 0;
  overflow: visible;
  position: absolute;
}

/* 
.experience_img_backdrop {
  height: 480px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000;
} */

/* ------Timeline -----*/
.timeline {
  /* position:relative; */
  margin: 50px auto;
  padding: 40px 0;
  /* width:1000px; */
  box-sizing: border-box;
}

.timeline .content {
  margin-top: -32px
}

.timeline:before {
  content: '';
  position: absolute;
  left: 50%;
  width: 2px;
  height: 100%;
  background: #ffffff0d;
}

.timeline ul {
  padding: 0;
  margin: 0;
}

.timeline ul li {
  list-style: none;
  position: relative;
  width: 50%;
  padding: 20px 40px;
  box-sizing: border-box;
}

.timeline ul li:nth-child(odd) {
  float: right;
  text-align: left;
  clear: both;
}

.timeline ul li:nth-child(even) {
  float: left;
  /* text-align:left; */
  clear: both;
}

.content {
  padding-bottom: 20px;
}

.timeline ul li:nth-child(even):before {
  content: '';
  position: absolute;
  width: 10px;
  height: 10px;
  top: 24px;
  right: -6px;
  background: rgb(76 76 76);
  border-radius: 50%;
  box-shadow: 0 0 0 3px #db8f0021
}

.timeline ul li:nth-child(odd):before {
  content: '';
  position: absolute;
  width: 10px;
  height: 10px;
  top: 24px;
  left: -4px;
  background: rgb(76 76 76);
  border-radius: 50%;
  box-shadow: 0 0 0 3px #db8f0021
}

.timeline ul li h3 {
  padding: 0;
  margin: 0;
  font-size: 36px;
  line-height: 72px;
  font-weight: 700;
}

.timeline ul li p {
  margin: 10px 0 0;
  padding: 0;
}

.timeline ul li .time h4 {
  margin: 0;
  padding: 0;
  font-size: 14px;
}

.timeline ul li:nth-child(odd) .time {
  position: absolute;
  top: 12px;
  right: -165px;
  margin: 0;
  padding: 8px 16px;
  background: rgba(233, 33, 99, 1);
  color: #fff;
  border-radius: 18px;
  box-shadow: 0 0 0 3px rgba(233, 33, 99, 0.3);
}

.timeline ul li:nth-child(even) .time {
  position: absolute;
  top: 12px;
  left: -165px;
  margin: 0;
  padding: 8px 16px;
  background: rgba(233, 33, 99, 1);
  color: #fff;
  border-radius: 18px;
  box-shadow: 0 0 0 3px rgba(233, 33, 99, 0.3);
}

@media(max-width:1000px) {
  .timeline {
    width: 100%;
  }
}

@media(max-width:767px) {


  .timeline {
    width: 100%;
    padding-bottom: 0;
  }

  .timeline:before {
    left: 20px;
    height: 100%;
  }

  .timeline ul li:nth-child(odd),
  .timeline ul li:nth-child(even) {
    width: 100%;
    text-align: left;
    padding-left: 50px;
    padding-bottom: 50px;
  }

  .timeline ul li:nth-child(odd):before,
  .timeline ul li:nth-child(even):before {
    top: -18px;
    left: 16px;
  }

  .timeline ul li:nth-child(odd) .time,
  .timeline ul li:nth-child(even) .time {
    top: -30px;
    left: 50px;
    right: inherit;
  }
}



.event span {
  margin-right: 10px;
  color: #00ff00;
  /* Checkmark color */
}

.event a {
  color: #ffa500;
  /* Link color */
  text-decoration: none;
}

.event a:hover {
  text-decoration: underline;
}


.heading {
  color: rgba(239, 247, 255, 0.62);
  font-size: 18px;
  line-height: 27px;
  margin-bottom: 1.8rem;
}

.gredient_divider {
  align-content: center;
  align-items: center;
  background: linear-gradient(90deg, rgba(255, 0, 0, 0) 0%, rgba(223, 239, 254, 0) .009999999747378752%, rgba(223, 239, 254, .14) 47.39583432674408%, rgba(223, 239, 254, 0) 98.95833134651184%, rgba(223, 239, 254, 0) 98.96833300590515%);
  display: flex;
  flex: none;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 0px;
  height: min-content;
  justify-content: center;
  max-width: 1140px;
  overflow: visible;
  padding: 0;
  position: relative;
  width: 100%;
}

.gredient_divider_inner {
  align-content: center;
  align-items: center;
  background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(255, 255, 255, 0) 0%, rgba(143, 143, 143, .6700000166893005) 50%, rgba(0, 0, 0, 0) 100%);
  display: flex;
  flex: none;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 10px;
  height: 1px;
  justify-content: center;
  padding: 0;
  position: relative;
  width: 300px;
}

.thumb_img_wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 0.8rem;
  justify-content: center
}

.thumb_img_box {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 228px;
  width: 228px;

  /* background-color: #000; */
}

.thumb_img_box img {
  width: 150px;
  transition: all .2s ease-in-out;
}

.thumb_img_box img:hover {
  transform: scale(1.1);
  cursor: pointer;
}


.copyright {
  color: rgb(115, 115, 115);
  font-size: 15px;
  line-height: 24px;
}

.footer_section {
  display: flex;
  justify-content: space-between;
}

.footer_heading {
  font-size: 1rem;
  color: #fff;
  font-weight: 600;
  margin-bottom: 0.8rem;
}

.footer_listing {
  display: flex;
  gap: 10rem
}

ul.footer_bullets {
  list-style: none;
  margin: 0;
  padding: 0;
}

ul.footer_bullets li a {
  font-size: 16px;
  color: rgba(239, 247, 255, .62);
  text-decoration: none;

}

ul.footer_bullets li a:hover {
  color: rgb(239, 247, 255);
}
@media (max-width: 992px){
  .sticky {   
   backdrop-filter: none!important
   
}
}
@media (max-width:600px) {
  .timeline .content {
    margin-top: -56px;
}
  
  .background-overlay {     
    background-size: 33%;    
 }
  .mob_nav {
    margin-top:2rem
  }

  .gift_dev_card_height {
    height:331px
  }
  .cs_card_bimg2 {
    height:200px!important;
  }
  .order2 {
    order: 2;
  }
  .main_heading {
    font-size: 56px;
    line-height: 46px;
  }

  .timeline ul li h3 {
    font-size: 27px;
    line-height: 56px;
  }

  .timeline ul li p {
    font-size: 15px;
  }

  .accordion-button {
    font-size: 15px;
    line-height: 26px;
  }

  .main_title {
    font-size: 35px;
    line-height: 43px;
  }

  .para {
    font-size: 14px;
    line-height: 24px;

  }

  .thumb_img_box img {
    width: 115px;
  }

  .dmt_coupon_bg_wrapper {
    aspect-ratio: unset;
    height: 795px;
  }

  .dmt_coupon_bg {
    height: 347px;
    left: 50%;
    top: 226px;
    transform: translate(-50%);
    width: 794px;
    transform: translateX(-50%) rotate(90deg) translateZ(0px);
  }

  .hide_on_mobile {
    display: none;
  }

  .token_img_backdrop {
    height: 215px !important;
    width: 150px !important;
  }

  .image_backdrop_inner {
    /* height:300px!important;  */
    width: 300px !important;
  }

  .experience_wrapper {
    height: auto;
  }

  .exp_backdrop_inner {
    height: 157px;
    width: 274px;
  }

  .experience_img_container {
    inset: 4px 50px 0;
  }

  .cs_card_bimg4 {     
    display: flex;
    justify-content: center;
    height: 210px;
   
}

.cs_card_bimg4 img{     
 width:220px!important;
 height:210px;
 
}
  .token_img_box {
    width: 55px;
    height: 55px;

  }

  .thumb_img_wrapper {
    align-content: unset;
    align-items: unset;
    display: grid;
    grid-auto-rows: minmax(0, 1fr);
    grid-template-columns: repeat(2, minmax(175px, 1fr));
    grid-template-rows: repeat(2, minmax(0, 1fr));
    justify-content: center;
    padding: 0 20px;
    width: 100%;
  }

  .thumb_img_box {
    height: unset;
    width: unset;
    margin-top: 1rem
  }

  .welcome_area {
    text-align: center;
    /* margin: auto; */
    padding: 3rem 1rem;
  }

  .footer_section {
    display: grid;
    gap: 2rem;
  }

  .accordion-body {
    font-size: 14px;
  }

  .dmt_content_wrapper {
    flex-direction: column;
    height: 790px;
    left: 0;
    padding: 0;
    top: calc(49.93710691823902% - 790px / 2);
    /* width: 37%; */
  }

  .dmt_content_left {
    width: unset !important;
  }

  .dmt_content_wrapper_right {
    width: 300px !important;
    height: 250px;
    /* margin-top: 2rem; */
  }

  .dmt_content_wrapper_right_card {

    aspect-ratio: 1 / 1;
    bottom: unset;
    height: 306px;
    left: 0;
    right: unset;
    top: -2px;
    width: 100%;
  }

  .power_img_wrapper {
    bottom: 0;
    height: 169px;
    width: 191px;
    left: calc(50.00000000000002% - 191px / 2);

  }

 
  .content-fold-img {
    bottom: -178px;
    right: 0;
    width: 150px;
  }

  .container_sm {
    width: 100% !important;
  }

  .content_fold_wrapper {
    width: 100% !important
  }
}

@media (max-width:786px) {
  .hide_on_mobile {
    display: none;
  }

  .welcome_area {
    text-align: center;
    padding: 3rem 1rem;
  }

  .content-fold-img {
    bottom: -178px;
    right: 0;
    width: 150px;
  }

  .cs_card_bimg4 {
    display: flex;
    justify-content: center;
    height: 210px;

  }

  .cs_card_bimg4 img {
    width: 220px !important;
    height: 210px;

  }

  .dmt_coupon_bg_wrapper {
    aspect-ratio: unset;
    height: 795px;
  }

  .dmt_coupon_bg {
    height: 347px;
    left: 50%;
    top: 226px;
    transform: translate(-50%);
    width: 794px;
    transform: translateX(-50%) rotate(90deg) translateZ(0px);
  }


  .dmt_content_wrapper {
    flex-direction: column;
    height: 790px;
    left: 0;
    padding: 0;
    top: calc(49.93710691823902% - 790px / 2);
    /* width: 37%; */
  }

  .dmt_content_wrapper_left {
    width:40%
  }
  .dmt_content_left {
    width: unset !important;
  }

  .dmt_content_wrapper_right {
    width: 300px !important;
    height: 250px;
    /* margin-top: 2rem; */
  }

  .dmt_content_wrapper_right_card {
    aspect-ratio: 1 / 1;
    bottom: unset;
    height: 306px;
    left: 0;
    right: unset;
    top: -2px;
    width: 100%;
  }


}